@font-face {
  font-family: 'SemiBold'; 
  src: url(fonts/Montserrat-SemiBold.ttf);
 }
 @font-face {
  font-family: 'Montserrat-Light'; 
  src: url(fonts/Montserrat-Light.ttf);
 }
 @font-face {
  font-family: 'Montserrat-Regular'; 
  src: url(fonts/Montserrat-Regular.ttf);
 }
 @font-face {
  font-family: 'Montserrat-Bold'; 
  src: url(fonts/Montserrat-Bold.ttf);
 }
 @font-face {
  font-family: 'Montserrat-Medium'; 
  src: url(fonts/Montserrat-Medium.ttf);
 }
 @font-face {
  font-family: 'Montserrat-ExtraBold'; 
  src: url(fonts/Montserrat-ExtraBold.ttf);
 }

body {
  margin: 0;
  padding: 0;
  font-family:'Montserrat-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #309DA1; 
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul{
  margin: 0;
  padding: 0;
}
li{
  list-style: none;
}
p{
  margin:0;
}
h1,h2,h3{
  margin: 0;
  padding: 0;

}
a{
  text-decoration: none;
}
